/** @format */

import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import { PrimarySelect } from './inputs';

interface IPaginator {
  selected: number;
  hasNext: boolean;
  totalPages: number;
  showCount?: number;
  onCountSelect?: (x: number) => void;
  click: (data: { selected: number }) => void;
}

export const Paginator: FC<IPaginator> = ({
  hasNext,
  selected,
  showCount,
  totalPages,
  click,
  onCountSelect,
}) => {
  const [count, setCount] = React.useState(30);

  const handleChangeCount = (
    event: React.SyntheticEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(event.currentTarget.value);

    setCount(value);

    if (onCountSelect) {
      onCountSelect(value);
    }
  };

  React.useEffect(() => {
    setCount(showCount ?? 30);
  }, [showCount]);

  if (totalPages >= 2)
    return (
      <div className="flex flex-col sm:flex-row justify-between items-center pb-5 lg:pb-10 mt-4">
        <div />

        <ReactPaginate
          breakLabel=".."
          nextLabel=">"
          onPageChange={click}
          pageRangeDisplayed={window.innerWidth <= 768 ? 2 : 3}
          pageCount={totalPages}
          previousLabel="<"
          containerClassName="flex items-center justify-center pt-2 text-dark-grey space-x-px"
          pageClassName="h-9 w-10 hover:bg-primary-600 hover:text-white transition ease-in-out duration-300 bg-accepted"
          previousClassName={`h-9 w-10 rounded-l flex items-center justify-center transition ease-in-out duration-300 bg-accepted ${
            selected === 0
              ? 'bg-neutral-300 text-white cursor-not-allowed'
              : 'hover:bg-primary-600 hover:text-white'
          }`}
          nextClassName={`h-9 w-10 rounded-r medium flex items-center justify-center transition ease-in-out duration-300 bg-accepted ${
            hasNext
              ? 'hover:bg-primary-600 hover:text-white'
              : 'bg-neutral-300 text-white cursor-not-allowed'
          }`}
          breakClassName="h-9 w-10 hover:bg-primary-600 hover:text-white transition ease-in-out duration-300 bg-accepted"
          pageLinkClassName="h-full w-full medium flex items-center justify-center overflow-hidden focus:outline-none"
          nextLinkClassName={`medium ${hasNext ? '' : 'cursor-not-allowed'}`}
          previousLinkClassName={`medium ${
            selected === 0 && 'cursor-not-allowed'
          }`}
          breakLinkClassName="h-full w-full flex items-center justify-center overflow-hidden focus:outline-none bg-accepted"
          activeLinkClassName="h-full w-full medium flex items-center bg-primary-600 text-white"
          forcePage={selected}
        />

        {showCount && onCountSelect ? (
          <PrimarySelect
            onChange={handleChangeCount}
            options={[10, 20, 30, 40, 50].map((v) => ({ label: v, value: v }))}
            optionLabel="label"
            value={count}
            optionValue="value"
            label="List count"
            name="count"
            placeholder="Select count"
            className="!mt-4 !sm:mt-0"
          />
        ) : (
          <div />
        )}
      </div>
    );
  return <></>;
};
