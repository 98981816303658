import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '..';
import { usersApi } from '../../api';
import {
  ICreateCustomer,
  ICreateUser,
  ICustomer,
  ISummary,
  IUser,
  ICreateNotification,
  NotificationOption,
  AdminRole,
  IOrder,
  IPagination,
  IWalletTransactions,
  Pagination,
} from '../../types';
import { genQuery } from '../../helpers';

interface IUserState {
  loading: boolean;
  users: { pagination?: Pagination; data: IUser[] };
  user: { user: IUser; summary: ISummary } | null;
  usersUnpaginated: IUser[];
  customers: { pagination: IPagination | null; data: ICustomer[] };
  customersUnpaginated: ICustomer[];
  customer: { customer: ICustomer; summary: ISummary } | null;
  transactions: any[];
  orders: IOrder[];
  searchedUsers: {
    pagination: IPagination | null;
    data: IUser[] | ICustomer[];
  };
  searchLoading: boolean;
  walletTransactions: {
    transactions: IWalletTransactions[];
    pagination: IPagination | null;
  };
}
const initialState: IUserState = {
  loading: false,
  users: { data: [] },
  usersUnpaginated: [],
  user: null,
  customers: { data: [], pagination: null },
  customersUnpaginated: [],
  customer: null,
  transactions: [],
  orders: [],
  searchedUsers: { pagination: null, data: [] },
  searchLoading: false,
  walletTransactions: { pagination: null, transactions: [] },
};

export const userSlice = createSlice({
  name: 'kitchen',
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.searchedUsers = { pagination: null, data: [] };
    },
  },
  extraReducers: (builder) => {
    // users

    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.users = { data: payload.data, pagination: payload.pagination };
    });
    builder.addCase(getAllUsers.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.fulfilled, (state) => {
      state.loading = false;
      toast.success('User created successfully');
    });
    builder.addCase(createUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.loading = false;
      toast.success('User updated successfully');
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAdmin.fulfilled, (state) => {
      state.loading = false;
      toast.success('Admin created Successfully');
    });
    builder.addCase(createAdmin.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(removeAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeAdmin.fulfilled, (state) => {
      state.loading = false;
      toast.success('Admin privileges removed');
    });
    builder.addCase(removeAdmin.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserOrders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.orders = payload;
    });
    builder.addCase(getUserOrders.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(searchUsers.pending, (state) => {
      state.searchLoading = true;
    });
    builder.addCase(searchUsers.fulfilled, (state, { payload }) => {
      state.searchLoading = false;
      state.searchedUsers = {
        data: payload.data,
        pagination: payload.pagination,
      };
    });
    builder.addCase(searchUsers.rejected, (state) => {
      state.searchLoading = false;
    });

    builder.addCase(getAllUsersUnpaginated.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllUsersUnpaginated.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.usersUnpaginated = payload;
    });
    builder.addCase(getAllUsersUnpaginated.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserWalletTransactions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getUserWalletTransactions.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.walletTransactions = {
          transactions: payload.data,
          pagination: payload.pagination,
        };
      }
    );
    builder.addCase(getUserWalletTransactions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createKitchenStaff.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createKitchenStaff.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(createKitchenStaff.rejected, (state) => {
      state.loading = false;
    });

    // customers

    builder.addCase(getAllCustomers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCustomers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.customers = {
        data: payload.data,
        pagination: payload.pagination,
      };
    });
    builder.addCase(getAllCustomers.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllCustomersUnpaginated.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllCustomersUnpaginated.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.customersUnpaginated = payload;
      }
    );
    builder.addCase(getAllCustomersUnpaginated.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomer.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.customer = payload;
    });
    builder.addCase(getCustomer.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCustomer.fulfilled, (state) => {
      state.loading = false;
      toast.success('Customer created successfully');
    });
    builder.addCase(createCustomer.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCustomer.fulfilled, (state) => {
      state.loading = false;
      toast.success('Customer updated successfully');
    });
    builder.addCase(updateCustomer.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCustomerOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomerOrders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.orders = payload;
    });
    builder.addCase(getCustomerOrders.rejected, (state) => {
      state.loading = false;
    });

    // notifications

    builder.addCase(sendNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendNotification.fulfilled, (state) => {
      state.loading = false;
      toast.success('Notifications Sent Successfully');
    });
    builder.addCase(sendNotification.rejected, (state) => {
      state.loading = false;
    });

    // push notifications

    builder.addCase(subscribeToNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      subscribeToNotifications.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.transactions = payload;
      }
    );
    builder.addCase(subscribeToNotifications.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createWallet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createWallet.fulfilled, (state) => {
      state.loading = false;
      toast.success('Wallet Created Successfully');
    });
    builder.addCase(createWallet.rejected, (state) => {
      state.loading = false;
    });
  },
});

// users

const getAllUsersUnpaginated = createAsyncThunk(
  'getAllUsersUnpaginated',
  async (params: { cb?: () => void }) => {
    try {
      const { cb } = params;
      const { data } = await usersApi.getAllUsersUnpaginated();
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllUsers = createAsyncThunk(
  'getAllUsers',
  async (params: { page?: number; query?: string }) => {
    try {
      const { page, query } = params;
      const { data } = await usersApi.getAllUsers(page, query);
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const searchUsers = createAsyncThunk(
  'searchUsers',
  async (params: { query?: string; page?: number }) => {
    try {
      const { query } = params;
      const { data } = await usersApi.searchUsers(query);
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getUser = createAsyncThunk('getUser', async (id: number) => {
  try {
    const { data } = await usersApi.getUser(id);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const createUser = createAsyncThunk(
  'createUser',
  async (params: { payload: ICreateUser; cb: () => void }, { dispatch }) => {
    try {
      const { payload, cb } = params;
      const { data } = await usersApi.createUser(payload);

      dispatch(getAllUsers({}));
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const updateUser = createAsyncThunk(
  'updateUser',
  async (params: { payload: ICreateUser; id: number; cb: () => void }) => {
    try {
      const { payload, id, cb } = params;
      const { data } = await usersApi.updateUser(payload, id);

      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getUserOrders = createAsyncThunk('getUserOrders', async (id: number) => {
  try {
    const { data } = await usersApi.getUserOrders(id);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getUserWalletTransactions = createAsyncThunk(
  'getUserWalletTransactions',
  async (params: { id: string; page?: number }) => {
    try {
      const { id, page } = params;
      const { data } = await usersApi.getUserWalletTransactions(id, page);
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

// customers

const getAllCustomersUnpaginated = createAsyncThunk(
  'getAllCustomersUnpaginated',
  async (params: { cb?: () => void }) => {
    try {
      const { cb } = params;
      const { data } = await usersApi.getAllCustomersUnpaginated();
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllCustomers = createAsyncThunk(
  'getAllCustomers',
  async (params: { page?: number; cb?: () => void }) => {
    try {
      const { page, cb } = params;
      const { data } = await usersApi.getAllCustomers({ page });
      cb && cb();
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getCustomer = createAsyncThunk('getCustomer', async (id: number) => {
  try {
    const { data } = await usersApi.getCustomer(id);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const createCustomer = createAsyncThunk(
  'createCustomer',
  async (
    params: { payload: ICreateCustomer; cb: () => void },
    { dispatch }
  ) => {
    try {
      const { payload, cb } = params;
      const { data } = await usersApi.createCustomer(payload);

      dispatch(getAllCustomersUnpaginated({}));
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const updateCustomer = createAsyncThunk(
  'updateCustomer',
  async (params: { payload: ICreateCustomer; id: number; cb: () => void }) => {
    try {
      const { payload, id, cb } = params;
      const { data } = await usersApi.updateCustomer(payload, id);

      cb && cb();

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getCustomerOrders = createAsyncThunk(
  'getCustomerOrders',
  async (id: number) => {
    try {
      const { data } = await usersApi.getCustomerOrders(id);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

// notifications
const sendNotification = createAsyncThunk(
  'sendNotification',
  async (params: {
    sendToAll?: boolean;
    payload: ICreateNotification;
    type: NotificationOption;
    cb?: () => void;
  }) => {
    try {
      const { payload, type, cb, sendToAll } = params;
      const { data } = await usersApi.sendNotification({
        payload,
        type,
        sendToAll,
      });
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

// admin

const createAdmin = createAsyncThunk(
  'createAdmin',
  async (params: { username: string; role: AdminRole; cb: () => void }) => {
    try {
      const { username, role, cb } = params;
      const { data } = await usersApi.createAdmin({ username, role });
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const createKitchenStaff = createAsyncThunk(
  'createKitchenStaff',
  async (params: { payload: ICreateUser; cb: (password: string) => void }) => {
    try {
      const { payload, cb } = params;
      const { data } = await usersApi.createKitchenStaff(payload);
      cb && cb(data.data.password);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const removeAdmin = createAsyncThunk(
  'removeAdmin',
  async (params: { username: string; cb: () => void }) => {
    try {
      const { username, cb } = params;
      const { data } = await usersApi.removeAdmin(username);
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

// push notifications

const subscribeToNotifications = createAsyncThunk(
  'subscribeToNotifications',
  async (platformId: string) => {
    try {
      const { data } = await usersApi.subscribeToNotifications(platformId);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const createWallet = createAsyncThunk(
  'createWallet',
  async (userId: string) => {
    try {
      const { data } = await usersApi.createWallet(userId);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const exportUsers = createAsyncThunk(
  'exportOrder',
  async (params: {
    payload: { from: string | null; till?: string | null };
    cb?: () => void;
  }) => {
    try {
      const { payload, cb } = params;

      const query = genQuery({ dateFrom: payload });

      const { data } = await usersApi.exportUserCsv(query);
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const userSelector = (state: RootState) => state.users;
export {
  getAllUsers,
  getAllCustomers,
  getAllCustomersUnpaginated,
  getCustomer,
  getUser,
  createCustomer,
  updateCustomer,
  createUser,
  updateUser,
  sendNotification,
  createAdmin,
  removeAdmin,
  getCustomerOrders,
  getUserOrders,
  subscribeToNotifications,
  createWallet,
  getAllUsersUnpaginated,
  searchUsers,
  getUserWalletTransactions,
  exportUsers,
  createKitchenStaff,
};

export const { resetSearch } = userSlice.actions;

export default userSlice.reducer;
