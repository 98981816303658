import Select, {
  ActionMeta,
  CSSObjectWithLabel,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  PropsValue,
  SingleValue,
} from 'react-select';

type IOptions<T> = OptionsOrGroups<T, GroupBase<T>>;

export type IOnChange<T> = (
  newValue: MultiValue<T> | SingleValue<T>,
  actionMeta: ActionMeta<T>
) => void;

interface ISelectInput<T> {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  options: IOptions<T>;
  required?: boolean;
  singleSelect?: boolean;
  value?: PropsValue<T>;
  handleChange: IOnChange<T>;
  filterOption?: (option: any, searchText: string) => boolean;
  getOptionLabel?: (x: T) => string;
  getOptionValue?: (x: T) => string;
}

export const SelectInput = <T,>({
  name,
  label,
  placeholder,
  options,
  required,
  singleSelect,
  className,
  value,
  handleChange,
  filterOption,
  getOptionLabel,
  getOptionValue,
}: ISelectInput<T>) => {
  return (
    <div className={`flex flex-col w-full ${className}`}>
      {label && (
        <label className="medium text-sm text-neutral-900 flex justify-between items-center mb-2">
          <span>{label}</span>
          {required && <span className="text-primary-700">*</span>}
        </label>
      )}
      <Select
        isMulti={singleSelect ? false : true}
        name={name}
        placeholder={placeholder}
        styles={{ control: selectInputStyle }}
        onChange={handleChange}
        value={value}
        options={options}
        filterOption={filterOption}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
    </div>
  );
};

const selectInputStyle = (
  baseStyles: CSSObjectWithLabel
): CSSObjectWithLabel => ({
  ...baseStyles,
  padding: '10px 0',
  outline: 'none',
  width: '100%',
});
