import classNames from 'classnames';
import { FC, forwardRef } from 'react';
import { InputProps, InputSize, InputVarients } from '../types';

const sizeMap: { [key in InputSize]: string } = {
  medium: 'p-3.5 text-base',
  large: 'p-4.5 text-base',
};

export const inputVariants: { [key in InputVarients]: string } = {
  primary:
    'border-neutral-500 rounded focus:border-neutral-700 focus:ring-neutral-500 p-4',
  secondary:
    'p-4 rounded border-neutral-600 focus:border-neutral-800 focus:ring-neutral-600',
  tertiary:
    'p-4 rounded border-primary-100 focus:border-primary-200 focus:ring-primary-100',
};

const prependPosMap: { [key in 'left' | 'right']: string } = {
  left: 'left-0 ml-3',
  right: 'right-0 mr-3',
};

export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      type = 'text',
      size = 'medium',
      className = '',
      placeholder,
      isError,
      group,
      groupClassName,
      onlyNumber,
      prependIcon,
      prependText,
      prependPosition = 'left',
      variant = 'primary',
      containerclassname,
      ...props
    },
    ref
  ) => {
    const prepend = prependIcon || prependText;
    return (
      <div
        className={classNames('!relative', {
          [containerclassname as string]: containerclassname,
        })}
      >
        <label
          htmlFor={props.name}
          className="text-xs semibold leading-7 text-neutral-900"
        >
          {label}
        </label>
        <div className="relative flex">
          {group && (
            <span
              className={classNames(
                'relative inline-flex w-full border border-gray-300 bg-transparent leading-none text-gray-700 placeholder-gray-500 transition-colors ease-in-out placeholder:text-sm hover:border-gray-900 focus:border-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-400 focus:ring-opacity-30',
                { [groupClassName as string]: groupClassName }
              )}
            >
              {group}
            </span>
          )}

          {prepend && (
            <span
              className={classNames(
                'absolute inset-y-0 flex items-center justify-center text-xs font-semibold',
                prependPosMap[prependPosition]
              )}
            >
              {prependIcon ? prependIcon : prependText}
            </span>
          )}

          <input
            ref={ref}
            type={type ?? 'text'}
            aria-label={label}
            placeholder={placeholder}
            className={classNames([
              '!relative inline-flex w-full border border-gray-300 bg-transparent leading-none text-gray-700 placeholder-gray-500 transition-colors duration-300 ease-in-out placeholder:text-sm focus:border-gray-500 focus:outline-none focus:ring-4 focus:ring-gray-300 focus:ring-opacity-30',
              sizeMap[size],
              inputVariants[variant],
              className,
              { 'rounded-r-lg': group, 'rounded-[4px]': !group },
              {
                '!hover:border-none !border-gray-300/50 !text-[#929090]':
                  props.disabled,
              },
              { 'pr-9': type === 'password' },
              { 'pr-9': prepend && prependPosition === 'right' },
              { 'pl-10': prepend && prependPosition === 'left' },
            ])}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key) && onlyNumber) {
                event.preventDefault();
              }
            }}
            {...props}
          />

          {type === 'password' ? (
            <span className="absolute inset-y-0 right-0 m-auto mr-3 flex cursor-pointer items-center justify-center text-xs font-semibold">
              {/* <button onClick={() => setShowPassword((prev) => !prev)}>
                <img src={showPassword ? eye : crossedEye} className="h-5" />
              </button> */}
            </span>
          ) : ['date', 'time'].includes(type) ? (
            <span className="absolute inset-y-0 right-0 m-auto mr-3 flex cursor-pointer items-center justify-center text-xs font-semibold">
              {/* You can replace this with your own date icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="16" y1="2" x2="16" y2="6"></line>
                <line x1="8" y1="2" x2="8" y2="6"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
              </svg>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
);
