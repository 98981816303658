import { ButtonHTMLAttributes, FC } from 'react';
import { add, close } from '../../../assets/svg';
import numeral from 'numeral';
import { LogoutLoader } from './loaders';

interface IPrimaryButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  loading?: boolean;
  onClick?: () => void;
  classNames?: string;
  disabled?: boolean;
}

export const PrimaryButton: FC<IPrimaryButton> = ({
  text,
  loading,
  onClick,
  classNames,
  disabled,
  ...props
}) => {
  return (
    <button
      className={`border rounded-lg py-3.5 px-6 text-sm medium text-white flex justify-center ${
        disabled ? 'cursor-not-allowed bg-primary-200' : 'bg-primary-700'
      } ${classNames}`}
      onClick={onClick}
      {...props}
    >
      {loading ? <LogoutLoader /> : text}
    </button>
  );
};

interface ISecondaryButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  onClick?: () => void;
  classNames?: string;
  red?: boolean;
}

export const SecondaryButton: FC<ISecondaryButton> = ({
  text,
  onClick,
  classNames,
  red,
  ...props
}) => {
  return (
    <button
      className={`bg-transparent border ${
        red
          ? 'border-primary-700 text-primary-700'
          : 'border-neutral-500 text-neutral-800'
      } rounded-lg py-3 px-6 text-sm medium ${classNames}`}
      onClick={onClick}
      {...props}
    >
      {text}
    </button>
  );
};

interface IToggleButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  active: boolean;
  count?: number;
}

export const ToggleButton: FC<IToggleButton> = ({
  text,
  active,
  count,
  ...props
}) => {
  return (
    <button
      className={`mt-5 rounded-md py-2 px-3 flex items-center text-sm mr-3 space-x-2 ${
        active ? 'bg-pink text-neutral-700' : 'bg-white'
      }`}
      {...props}
    >
      <p>{text}</p>{' '}
      {typeof count === 'number' && (
        <p className="bold">{numeral(count).format('0,0')}</p>
      )}
    </button>
  );
};

interface ICloseButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  imgClassName?: string;
}

export const MinimalClose: FC<ICloseButton> = ({
  onClick,
  imgClassName,
  ...props
}) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      {...props}
      className={`${
        props.className || 'absolute right-2 top-5 focus:outline-none'
      }`}
    >
      <img src={close} alt="remove" className={`h-4 ${imgClassName}`} />
    </button>
  );
};

interface IAddButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  toggle?: boolean;
  toggleProperty?: boolean;
  text: string;
  toggledText?: string;
}

export const AddButton: FC<IAddButton> = ({
  onClick,
  toggle,
  toggleProperty,
  toggledText,
  text,
  ...props
}) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      {...props}
      className={`text-primary-700 medium flex items-center mt-4 focus:outline-none ${props.className}`}
    >
      <img
        src={add}
        alt="add"
        className={`mr-3 transform transition duration-200 ${
          toggle ? (!toggleProperty ? '' : 'rotate-45') : ''
        }`}
      />
      {toggle ? (toggleProperty ? toggledText : text) : text}
    </button>
  );
};

export const EditButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="px-1 py-0.5 bg-primary-brown text-white text-xs rounded"
    >
      edit
    </button>
  );
};
