/* eslint-disable array-callback-return */
import moment from 'moment';
import { SyntheticEvent } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { storeRef } from '..';
import { IOrder, Option, OrderStatus } from '../types';
import { format, isToday, differenceInMinutes } from 'date-fns';

export const onGetInputValue = <T>(
  event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>,
  type: 'string' | 'number',
  payload: T,
  setPayload: (x: T) => any
) => {
  const value = event.currentTarget.value;
  const name = event.currentTarget.name;

  setPayload({
    ...payload,
    [name]: type === 'number' ? parseInt(value) : value,
  });
};

export const onGetMultiSelectValue = <X, Y>(
  value:
    | MultiValue<{ value: Y; label: string | undefined }>
    | SingleValue<{ value: any; label: any }>,
  name: string,
  payload: X,
  setPayload: (x: X) => any
) => {
  if (Array.isArray(value)) {
    setPayload({
      ...payload,
      [name]: value.map((item) => ({
        id: item.value,
      })),
    });
  }
};

export const genQuery = ({
  range,
  query,
  brand,
  status,
  dateFrom,
  notstatus,
  displayCount,
}: {
  brand?: string;
  query?: string;
  displayCount?: number;
  status?: OrderStatus[];
  notstatus?: OrderStatus[];
  range?: string | { startDate: Date | null; endDate: Date | null };
  dateFrom?: { from: string | null; till?: string | null };
}) => {
  let generatedQuery = '';

  if (displayCount) {
    generatedQuery += `&limit=${displayCount}`;
  }

  if (typeof range === 'string' && range) {
    generatedQuery += `&dateRange=${range}`;
  }

  if (dateFrom && dateFrom?.from)
    generatedQuery += `?from=${moment(dateFrom.from)
      .set({ hour: 1, minute: 0 })
      .toISOString()}`;

  if (dateFrom && dateFrom.till)
    generatedQuery += `&till=${moment(dateFrom.till)
      .set({ hour: 24, minute: 59 })
      .toISOString()}`;

  if (range && typeof range !== 'string')
    generatedQuery += `&from=${moment(range.startDate)
      .set({ hour: 1, minute: 0 })
      .toISOString()}&till=${moment(range.endDate)
      .set({ hour: 1, minute: 0 })
      .toISOString()}`;

  if (query) generatedQuery += `&kitchenId=${query}`;

  if (status && status.length > 0)
    generatedQuery += `&${status.map((st) => `status[]=${st}`).join('&')}`;

  if (notstatus && notstatus.length > 0)
    generatedQuery += `&${notstatus
      .map((st) => `notstatus[]=${st}`)
      .join('&')}`;

  if (brand) generatedQuery += `&brandId=${brand}`;

  return generatedQuery;
};

export const genFilterQuery = () => {
  const { filter } = storeRef.getState();
  const { module } = filter;
  const activeModule = filter[module];
  const { startDate, endDate } = activeModule.dateRange;

  let query = '';

  startDate && (query += `&from=${moment(startDate).toISOString()}`);
  endDate && (query += `&till=${moment(endDate).toISOString()}`);

  activeModule.provider && (query += `&provider=${activeModule.provider}`);

  activeModule.transactionType &&
    (query +=
      activeModule.transactionType === 'credit'
        ? `&amount[gt]=0`
        : `&amount[lt]=0`);

  activeModule.amount &&
    activeModule.amount.max &&
    (query += `&amount[lte]=${activeModule.amount.max}`);

  activeModule.amount &&
    activeModule.amount.min &&
    (query += `&amount[gte]=${activeModule.amount.min}`);

  return query;
};

export const formatDate = (date: Date | string) => {
  return typeof date === 'string'
    ? date
    : [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-');
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const isObjectEmpty = (obj: object) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

export const stringifyParameters = (params: any) => {
  return !params || isObjectEmpty(params)
    ? ''
    : `?${Object.keys(params)
        .map((key) => {
          if (typeof params[key] === 'object') {
            return params[key]
              .map((item: string) => `${key}[]=${item}`)
              .join('&');
          } else if (!!params[key]) {
            return `${key}=${params[key]}`;
          }
        })
        .join('&')}`;
};

export const getOptionPrice = (option: Option) =>
  option.price !== null ? option.price : option.sameAsFoodItem?.price ?? 0;

export const maxProcessingMinutes = (o: IOrder) => {
  const distance = (o?.estimatedDistanceTravelled ?? 0) / 1000;

  if (distance >= 0 && distance <= 3.4) {
    return 30;
  }
  if (distance >= 3.5 && distance <= 6.4) {
    return 45;
  }
  if (distance >= 6.5) {
    return 60;
  }

  return 30;
};

export const shouldFlag = (o: IOrder) => {
  if (
    o.timePaid &&
    o.timePlaced &&
    o.status !== 'DELIVERED' &&
    o.status !== 'CANCELED' &&
    o.status !== 'SCHEDULED'
  ) {
    const processingTime = Math.abs(
      differenceInMinutes(
        new Date(o.scheduledTime ? o.timePlaced : o.timePaid),
        new Date()
      )
    );

    if (processingTime >= maxProcessingMinutes(o)) {
      return true;
    }

    return false;
  }

  return false;
};

export const filterFlaggedOrders = (orders: IOrder[]) => {
  return orders.filter((o) => !shouldFlag(o));
};

/**
 * Creates a debounced function that delays invoking `func` until after `delay` milliseconds
 * have elapsed since the last time the debounced function was invoked.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The number of milliseconds to delay.
 * @returns {Function} A new debounced function.
 */

export const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const convertArraysToString = <T extends Record<string, any>>(
  obj: T,
  separator: string = ', '
): T => {
  const result: T = { ...obj };

  for (const key in result) {
    if (Array.isArray(result[key])) {
      if (result[key].length > 0) {
        result[key] = result[key].join(separator);
      } else {
        delete result[key];
      }
    }
  }

  return result;
};

export const getFilterCount = (filters: any) => {
  const count = Object.entries(filters).filter(
    ([key, value]) => value !== undefined && !(key === 'from' || key === 'to')
  ).length;

  return count + (filters.from || filters.to ? 1 : 0);
};

export const removeUndefinedValues = (obj: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
};

export const addDaysToDate = (date: Date, days: number): Date => {
  const newDate = new Date(date.valueOf());
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const TodayDate = new Date();

export const getFormattedDate = (date?: string) => {
  if (date) {
    const jsDate = new Date(date);
    const formatTemplate = isToday(jsDate)
      ? 'hh:mm aa'
      : 'h:mm aaa. EEE, dd MMM Y';
    return format(jsDate, formatTemplate);
  } else return '';
};
