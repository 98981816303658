import { filters, tableData } from '../../../../constants';
import { useListFoodItems } from '../../../../hooks';
import { FoodItemParams } from '../../../../types';
import {
  Drawer,
  Loading,
  Paginator,
  PrimaryButton,
  PrimarySelect,
  SearchWithQuery,
  Table,
} from '../../../components';
import CreateFoodItem from './create';
import Modal from 'react-modal';
import { AddFoodGroup, AddOption } from './create/add-food-group';

const FoodItemsScreen = () => {
  const {
    data,
    modal,
    brands,
    loading,
    options,
    isOwner,
    isLoading,
    editGroup,
    foodGroups,
    editOption,
    openDrawer,
    currentPage,
    searchParams,
    activeFilter,
    groupsLoading,
    optionsLoading,
    navigate,
    editItem,
    closeModal,
    delistItem,
    createItem,
    filterList,
    filterTable,
    duplicateItem,
    setOpenDrawer,
    filterFoodItems,
    handlePageClick,
  } = useListFoodItems();

  return (
    <>
      <div className="body1">
        {((isLoading && activeFilter === 'FOOD_ITEMS') ||
          (groupsLoading && activeFilter === 'FOOD_GROUPS') ||
          (optionsLoading && activeFilter === 'OPTIONS') ||
          loading) && <Loading />}
        <>
          <div className="header1">
            <h1 className="semibold text-xl">Food Item Management</h1>
            <div className="flex space-x-4">
              <PrimaryButton
                text={`Create ${
                  tableData(data, foodGroups, options)[activeFilter].name
                }`}
                onClick={createItem}
              />
            </div>
          </div>
        </>

        <div className="grid sm:grid-cols-3 gap-4 mt-4">
          {filters(
            data?.pagination?.totalRecords,
            foodGroups?.pagination?.totalRecords,
            options?.pagination?.totalRecords
          ).map(({ name, value, count }) => (
            <button
              onClick={() => filterList(value)}
              className={`p-4 border rounded flex justify-between ${
                activeFilter === value
                  ? 'border-primary-brown bg-primary-brown text-white'
                  : 'text-primary-600'
              }`}
              key={value}
            >
              <p className="bold">{name}</p>
              <p className="bold">{count}</p>
            </button>
          ))}
        </div>

        <div className="flex flex-col sm:flex-row sm:space-x-4">
          {activeFilter === 'FOOD_ITEMS' && (
            <PrimarySelect
              onChange={filterFoodItems}
              placeholder="All Brands"
              options={brands}
              optionLabel="name"
              optionValue="id"
            />
          )}

          {activeFilter === 'FOOD_ITEMS' && (
            <SearchWithQuery<FoodItemParams>
              params={searchParams}
              paramTitle="foodItemName"
              placeholder="Search..."
              applyFilter={filterTable}
            />
          )}

          {activeFilter === 'FOOD_GROUPS' && (
            <SearchWithQuery<FoodItemParams>
              params={searchParams}
              paramTitle="foodGroupName"
              placeholder="Search..."
              applyFilter={filterTable}
            />
          )}

          {activeFilter === 'OPTIONS' && (
            <SearchWithQuery<FoodItemParams>
              params={searchParams}
              paramTitle="foodGroupOptionName"
              placeholder="Search..."
              applyFilter={filterTable}
            />
          )}
        </div>

        <Table
          showSearch={false}
          data={tableData(data, foodGroups, options)[activeFilter].data}
          columns={
            tableData(data, foodGroups, options, brands, isOwner)[activeFilter]
              .column
          }
          tableFor={tableData(data, foodGroups, options)[activeFilter].tableFor}
          editMethod={editItem}
          openPage={editItem}
          duplicateMethod={duplicateItem}
          deleteMethod={delistItem}
        />

        <Paginator
          click={handlePageClick}
          totalPages={
            tableData(data, foodGroups, options)[activeFilter].pagination
              ?.totalPages || 1
          }
          selected={currentPage - 1}
          hasNext={
            tableData(data, foodGroups, options)[activeFilter].pagination
              ?.hasNext || false
          }
        />
      </div>

      <Drawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        closeDrawer={() => {
          navigate(-1);
        }}
      >
        <CreateFoodItem closeDrawer={() => navigate(-1)} />
      </Drawer>

      <Modal
        isOpen={modal !== 0}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className={`w-11/12 sm:w-[630px] default-modal`}
        overlayClassName="modal-overlay"
      >
        {(modal === 1 || modal === 2) && (
          <AddFoodGroup
            closeModal={closeModal}
            duplicate={modal === 2}
            group={editGroup}
          />
        )}
        {(modal === 3 || modal === 4) && (
          <AddOption
            duplicate={modal === 4}
            editOption={editOption}
            toggle={closeModal}
          />
        )}
      </Modal>
    </>
  );
};

export default FoodItemsScreen;
