import { Cell } from 'react-table';
import { statusColors } from './orders.constants';
import { CouponUse, ICoupon } from '../types';
import numeral from 'numeral';
import moment from 'moment';
import { naira } from './general.constants';

export const couponsColumns = [
  {
    Header: 'Coupon Code',
    accessor: 'code',
  },
  {
    Header: 'Coupon Status',
    accessor: 'enabled',
    Cell: ({ row }: Cell) => {
      if (row.values.enabled) {
        return (
          <div className="flex items-center space-x-4 uppercase">
            <div
              className={`h-2 w-2 rounded-full mr-2 ${
                statusColors['Enabled'] || 'bg-pink'
              }`}
            />{' '}
            Enabled
          </div>
        );
      } else {
        return (
          <div className="flex items-center space-x-4 uppercase">
            <div
              className={`h-2 w-2 rounded-full mr-2 ${
                statusColors['Disabled'] || 'bg-pink'
              }`}
            />{' '}
            Disabled
          </div>
        );
      }
    },
  },
  {
    Header: 'Coupon Visibility',
    accessor: 'hidden',
    Cell: ({ row }: Cell) => {
      if (row.values.hidden) {
        return <p className="flex items-center space-x-4 uppercase">HIDDEN</p>;
      } else {
        return <p className="flex items-center space-x-4 uppercase">VISIBLE</p>;
      }
    },
  },
  {
    Header: 'Coupon type',
    accessor: 'specialType',
    Cell: ({ row }: Cell) => (
      <p className="flex items-center space-x-4">
        {row.values.specialType ? 'Promo coupon' : 'Regular coupon'}
      </p>
    ),
  },
  {
    Header: 'Total uses',
    accessor: '_count',
    Cell: ({ row }: Cell) => row.values?._count?.uses ?? '-',
  },
  {
    Header: 'Type',
    accessor: 'isPercent',
    Cell: ({ row }: Cell) => {
      if (row.values.isPercent) {
        return 'Percentage';
      }
      if (row.values.freeDelivery) {
        return 'Free Delivery';
      }
      return 'Discount Amount';
    },
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
  },
  {
    Header: 'Action',
  },
];

export const couponUsesColumns = [
  {
    Header: 'Full Name',
    accessor: 'firstName',
    Cell: ({ row }: Cell) =>
      `${(row.original as any).user.firstName} ${
        (row.original as any).user.lastName
      }`,
  },
  {
    Header: 'Email Address',
    accessor: 'user',
    Cell: ({ row }: Cell) => `${row.values?.user?.email}`,
  },
  {
    Header: 'Date used',
    accessor: 'date_used',
    Cell: ({ row }: Cell) =>
      `${moment(row.values?.date_used).format('Do MMMM YYYY, H:mm')}`,
  },
];

export const couponUsersColumns = [
  {
    Header: 'Full Name',
    accessor: 'firstName',
    Cell: ({ row }: Cell) =>
      `${(row.original as { firstName: string }).firstName} ${
        (row.original as { lastName: string }).lastName
      }`,
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    Cell: ({ row }: Cell) => `${row.values?.email}`,
  },
];

export const cashbackColumns = [
  {
    Header: 'Full name',
    accessor: 'user',
    Cell: ({ row }: Cell) =>
      `${row.values.user.firstName} ${row.values.user.lastName}`,
  },
  {
    Header: 'Email Address',
    Cell: ({ row }: Cell) => `${row.values?.user?.email}`,
  },
  {
    Header: 'Date used',
    accessor: 'usage_date',
    Cell: ({ row }: Cell) =>
      `${moment(row.values?.date_used).format('Do MMMM YYYY, H:mm')}`,
  },
  {
    Header: 'Amount spent',
    accessor: 'amount_spent',
    Cell: ({ row }: Cell) =>
      `${naira}${numeral(row.values?.amount_spent).format('0,0')}`,
  },

  {
    Header: 'Cashback value',
    accessor: 'cashback_amount',
    Cell: ({ row }: Cell) =>
      `${naira}${numeral(row.values?.cashback_amount).format('0,0')}`,
  },
];

export const couponStatus = [
  { value: '0', label: 'Disabled' },
  { value: '1', label: 'Enabled' },
];

export const couponVisibility = [
  { value: '1', label: 'Hidden' },
  { value: '0', label: 'Visible' },
];

export const discountBrandOption = [
  { value: '0', label: 'No' },
  { value: '1', label: 'Yes' },
];

export const couponType = [
  { value: 'percent', label: 'Percent' },
  { value: 'amount', label: 'Discount Amount' },
  { value: 'delivery', label: 'Free Delivery' },
];

export const couponUseInfo = (use: CouponUse) => [
  { title: 'Full Name', content: `${use.user.firstName} ${use.user.lastName}` },
  { title: 'Email', content: `${use.user.email}` },
  {
    title: 'Date used',
    content: moment(use.date_used).format('Do MMMM YYYY, H:mm'),
  },
];

export const couponDetails = (
  coupon?: ICoupon | null,
  assignedCount?: number
) =>
  coupon
    ? [
        {
          title: 'Coupon Type',
          content: coupon.isPercent
            ? 'Percentage'
            : coupon.freeDelivery
            ? 'Free Delivery'
            : 'Discount Amount',
        },
        {
          title: 'Discount Amount',
          content: coupon.isPercent
            ? `${coupon.discountAmount}%`
            : `₦${numeral(coupon.discountAmount).format('0,0')}`,
          show: !coupon.freeDelivery,
        },
        {
          title: 'Status',
          content: coupon.enabled ? 'Enabled' : 'Disabled',
        },
        {
          title: 'Visibility',
          content: coupon.hidden ? 'Hidden' : 'Visible',
        },

        {
          title: 'Minimum Total Price',
          content: `₦${numeral(coupon?.minTotalPrice).format('0,0')}`,
        },
        {
          title: 'Maximum Uses Total',
          content: `${numeral(coupon?.maxUsesTotal).format('0,0')}`,
        },
        {
          title: 'Maximum Uses Per User',
          content: `${numeral(coupon?.maxUsesPerUser).format('0,0')}`,
        },
        {
          title: 'Total users',
          content: `${numeral(assignedCount).format('0,0')}`,
        },
        {
          title: 'Total uses',
          content: `${
            coupon._count?.uses
              ? numeral(coupon._count?.uses).format('0,0')
              : '-'
          }`,
        },
        {
          title: 'Start Date',
          content: coupon?.startDate
            ? moment(coupon?.startDate).format('Do MMMM YYYY, H:mm')
            : '-',
        },
        {
          title: 'End Date',
          content: coupon?.endDate
            ? moment(coupon?.endDate).format('Do MMMM YYYY, H:mm')
            : '-',
        },
      ]
    : [];
