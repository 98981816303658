import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { close as closeIcon } from '../../../assets/svg';
import { orderStatus, paymentStatus, riderStatus } from '../../../constants';
import {
  useAppDispatch,
  useAppSelector,
  useFetch,
  usePermissions,
} from '../../../hooks';
import {
  getAllOrders,
  getOrder,
  orderSelector,
  payForMe,
  unassignPilot,
  updateOrder,
} from '../../../store/slices';
import { OrderStatus, Pilot, RiderStatus } from '../../../types';
import {
  CopyText,
  LogoutLoader,
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
} from '../shared';
import { RiderSelect } from '../../features/dashboard/orders/createOrder';

interface IQuickActions {
  toggle: () => void;
  activeFilter?: string;
}

export const QuickActions: FC<IQuickActions> = ({ activeFilter, toggle }) => {
  const { isOwner, userRole } = usePermissions();

  const dispatch = useAppDispatch();
  const { order, generatingLink, payForMeLink } = useAppSelector(orderSelector);

  const { useFetchAllPilots } = useFetch();
  const { data: pilots } = useFetchAllPilots(
    order?.kitchens[0].slug,
    { count: 500 },
    { enabled: !!order?.kitchens[0].slug }
  );

  const [quickUpdates, setQuickUpdates] = useState<{
    status: OrderStatus;
    paidFor: boolean;
    notes?: string;
    pilot?: number | Pilot;
    riderStatus?: RiderStatus;
  }>({
    status: 'PLACED',
    paidFor: false,
    notes: '',
  });

  const onGetInputValue = (
    event: SyntheticEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === 'paidFor' && typeof value === 'string') {
      return setQuickUpdates({
        ...quickUpdates,
        [name]: value === '0' ? false : true,
      });
    }

    if (name === 'pilot') {
      return setQuickUpdates({
        ...quickUpdates,
        [name]: parseInt(value),
      });
    }

    return setQuickUpdates({ ...quickUpdates, [name]: value });
  };

  const removePilot = async () => {
    dispatch(
      unassignPilot({
        id: order!.id,
        cb: () => dispatch(getOrder(order!.id)),
      })
    );
  };

  const afterUpdate = () => {
    toggle();
    dispatch(
      getAllOrders({
        status: activeFilter ? [activeFilter as OrderStatus] : undefined,
      })
    );
  };

  const _updateOrder = async (event?: SyntheticEvent<HTMLFormElement>) => {
    if (!order) return;
    event?.preventDefault();

    let finalPayload = {
      status: quickUpdates.status,
      paidFor: quickUpdates.paidFor,
      notes: quickUpdates.notes,
      pilot: quickUpdates.pilot,
    };

    if (order.pilotId && quickUpdates.pilot === undefined) {
      await removePilot();
    }

    dispatch(
      updateOrder({
        payload: finalPayload,
        id: order.id,
        cb: afterUpdate,
      })
    );
  };

  useEffect(() => {
    if (order) {
      setQuickUpdates({
        status: order.status ?? 'PLACED',
        paidFor: order.paidFor ?? false,
        notes: order.notes,
        pilot: order.pilotId,
        riderStatus: order.riderStatus,
      });
    }
  }, [order]);

  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center">
        <p className="bold text-2xl">Quick Actions</p>
        <button onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      {order && (
        <div>
          <p className="text-sm">{order?.slug}</p>
          <p className="mt-2">
            <span className="semibold">Customer Name:</span>{' '}
            {order?.customer
              ? order?.customer.fullName
              : order?.user.firstName + ' ' + order?.user.lastName}
          </p>
        </div>
      )}

      <form className="mt-2" onSubmit={_updateOrder}>
        <div className="flex flex-wrap justify-between items-end">
          <PrimarySelect
            onChange={onGetInputValue}
            options={orderStatus.filter(
              (s) =>
                !s.hideFor?.some((hideValue) => userRole?.includes(hideValue))
            )}
            optionLabel="label"
            value={quickUpdates.status}
            optionValue="value"
            label="Order Status"
            name="status"
            placeholder="Select status"
            className="w-full"
            disabled={quickUpdates.status === 'SCHEDULED' && !isOwner}
          />

          <PrimarySelect
            onChange={onGetInputValue}
            options={riderStatus.filter(
              (s) =>
                !s.hideFor?.some((hideValue) => userRole?.includes(hideValue))
            )}
            optionLabel="label"
            value={quickUpdates.riderStatus}
            optionValue="value"
            label="Rider Status"
            name="status"
            placeholder="Select status"
            className="w-full"
          />

          <RiderSelect
            pilots={pilots?.data ?? []}
            selectedPilot={pilots?.data?.find(
              (p) => p.id === quickUpdates?.pilot
            )}
            selectPilot={(id) =>
              setQuickUpdates({
                ...quickUpdates,
                pilot: id,
              })
            }
          />

          {isOwner && (
            <PrimarySelect
              onChange={onGetInputValue}
              options={paymentStatus}
              optionLabel="name"
              value={quickUpdates?.paidFor ? 1 : 0}
              optionValue="value"
              label="Payment Status"
              name="paidFor"
              placeholder="Select Payment Status"
              className="w-full"
            />
          )}

          <PrimaryInput
            onChange={onGetInputValue}
            value={quickUpdates?.notes}
            placeholder="Enter order notes"
            name="notes"
            label="Order notes"
            className="w-full"
          />

          {order && !order?.paidFor && (
            <div className="mt-5 w-full">
              {!payForMeLink && (
                <div className="flex space-x-4 items-center">
                  <button
                    type="button"
                    className="text-primary-700"
                    onClick={() => dispatch(payForMe(order.id))}
                  >
                    Generate 'Pay for me' link
                  </button>

                  {generatingLink && <LogoutLoader />}
                </div>
              )}

              {payForMeLink && (
                <>
                  <p className="text-sm mt-3">Pay for me link</p>
                  <div className="mt-1 flex space-x-2 justify-between">
                    <div className="px-2 py-1 rounded border border-neutral-400 w-10/12">
                      <p className="truncate">{payForMeLink}</p>
                    </div>
                    <CopyText text={payForMeLink} />
                  </div>
                </>
              )}
            </div>
          )}

          <div className="mt-4">
            <PrimaryButton text={`Update Order`} type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
};
