import { stringifyParameters } from '../helpers';
import { ILoyaltyApi } from '../types/loyalty.types';
import { instance } from './instance';

export const loyaltyApi: ILoyaltyApi = {
  listCoinTransaction: async (params) =>
    (await instance.get('/loyalty/all/transactions/', { params })).data,

  createTier: async (payload) =>
    await instance.post('/loyalty/add/tier/', payload),

  getAllTier: async (params) =>
    (await instance.get('/loyalty/tiers/', { params })).data.data,

  addAction: async (payload) =>
    await instance.post('/loyalty/add/action/', payload),

  getAllAction: async (params) =>
    await instance.get('/loyalty/all/actions/', { params }),

  redeemCoin: async (payload) =>
    await instance.post('/loyalty/redeem/', payload),

  updateAction: async (payload) =>
    await instance.patch('/loyalty/edit/action/', payload),

  editTier: async (payload) =>
    await instance.patch('/loyalty/edit/tier/', payload),

  getLoyaltyDashboard: async (params) => {
    const payload = stringifyParameters(params);

    const data = await instance.get(`/loyalty/dashboard${payload}`);

    return data.data.data;
  },

  getAllActions: async (params) => {
    const payload = stringifyParameters(params);

    const data = await instance.get(`/loyalty/all/actions${payload}`);

    return data.data.data;
  },
};
