import React, { SyntheticEvent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { couponsColumns } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  bulkUploadCoupon,
  couponSelector,
  getAllCoupons,
} from '../../../../store/slices';
import {
  NoData,
  Paginator,
  PrimaryButton,
  PrimaryInput,
  SearchWithQuery,
  SecondaryButton,
  Table,
  UploadFileNoDrag,
} from '../../../components';
import Modal from 'react-modal';
import { CouponFilter, CouponUseFilter } from '../../../../types';
import { filter } from '../../../../assets/svg';

const Coupons = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [page, setPage] = React.useState(1);
  const [modal, setModal] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);

  const dispatch = useAppDispatch();
  const { loading, coupons } = useAppSelector(couponSelector);

  const currentPage = React.useMemo(
    () => coupons?.pagination?.currentPage || 1,
    [coupons?.pagination?.currentPage]
  );

  const createCoupon = () => {
    navigate(`/dashboard/coupons/create`);
  };

  const navigateToCashback = () => {
    navigate(`/dashboard/coupons/cashback`);
  };

  const couponPage = (slug: string) => {
    navigate(`/dashboard/coupon/${slug}`);
  };

  const filterCoupons = (filters: CouponFilter) => {
    searchParams.set('userEmail', filters.userEmail ?? '');
    searchParams.set('couponCode', filters.couponCode ?? '');
    searchParams.set('page', '1');

    navigate(`/dashboard/coupons?${searchParams.toString()}`);
    setShowFilters(false);
  };

  const resetFilters = () => {
    setShowFilters(false);
    navigate(`/dashboard/coupons`);
  };

  const handlePageClick = (data: any) => {
    const page = data.selected + 1;
    setPage(data.selected + 1);

    searchParams.set('page', page);
    navigate(`/dashboard/coupons?${searchParams.toString()}`);
  };

  const editCoupon = (slug: string, e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(`/dashboard/coupons/edit/${slug}`);
  };

  React.useEffect(() => {
    const userEmail = searchParams.get('userEmail');
    const page = searchParams.get('page');
    const code = searchParams.get('couponCode');

    if (page) {
      setPage(parseInt(page));
    }

    if ((code && code?.length >= 3) || !code) {
      dispatch(
        getAllCoupons({
          params: {
            page: parseInt(page ?? '1'),
            userEmail: userEmail ?? undefined,
            couponCode: code?.toUpperCase() ?? undefined,
          },
        })
      );
    }
  }, [dispatch, page, searchParams]);

  return (
    <>
      {coupons?.data && coupons?.data?.length > 0 ? (
        <div className="body1">
          <div className="header1">
            <div>
              <h1 className="semibold text-xl mb-2 lg:mb-0">
                Coupon Management
              </h1>
              <SearchWithQuery<CouponFilter>
                params={searchParams}
                paramTitle="couponCode"
                applyFilter={filterCoupons}
              />
              <p className="text-xs font-bold text-gray-500">
                Enter three or more characters to search
              </p>
            </div>

            <div className="flex space-x-4">
              <PrimaryButton text="Cashback" onClick={navigateToCashback} />

              <PrimaryButton text="Add New Coupon" onClick={createCoupon} />

              <SecondaryButton
                text="Bulk upload coupon"
                onClick={() => setModal(true)}
              />

              {showFilters && (
                <div
                  onClick={() => setShowFilters(false)}
                  className="absolute bg-black bg-opacity-20 inset-0 h-full w-full z-10"
                />
              )}

              <div className="relative">
                <FilterCoupons
                  params={searchParams}
                  showFilters={showFilters}
                  resetFilters={resetFilters}
                  applyFilters={filterCoupons}
                  setShowFilters={setShowFilters}
                />
              </div>
            </div>
          </div>

          <Table
            columns={couponsColumns}
            data={coupons.data}
            editMethod={editCoupon}
            tableFor={'coupons'}
            openPage={couponPage}
            showSearch={false}
          />

          <Paginator
            click={handlePageClick}
            totalPages={coupons?.pagination?.totalPages || 1}
            selected={currentPage - 1}
            hasNext={coupons?.pagination?.hasNext || false}
          />
        </div>
      ) : (
        !loading && (
          <>
            <NoData
              title="No available coupons"
              description="You haven’t added any coupons. Start adding coupons by clicking the button below."
              buttonText="Add New Coupon"
              onclick={createCoupon}
              secondaryButton={
                searchParams.toString() && (
                  <button
                    type="button"
                    className="underline text-primary-700 mt-10"
                    onClick={resetFilters}
                  >
                    Reset filters
                  </button>
                )
              }
            />
          </>
        )
      )}

      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        ariaHideApp={false}
        className={`w-11/12 sm:w-5/12 default-modal`}
        overlayClassName="modal-overlay"
      >
        <BulkUploadCoupons closeModal={() => setModal(false)} />
      </Modal>
    </>
  );
};

export default Coupons;

const BulkUploadCoupons = ({ closeModal }: { closeModal: () => void }) => {
  const dispatch = useAppDispatch();

  const [code, setCode] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const handleFileChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      setSelectedFile(event.currentTarget.files[0]);
    }
  };

  const onSubmit = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('coupon_csv', selectedFile);

      dispatch(bulkUploadCoupon({ code, payload: formData, cb: closeModal }));
    }
  };

  return (
    <div className="bg-white p-4">
      <p className="text-lg">Upload Bulk Coupon</p>

      <p className="text-sm text-neutral-500">
        You can upload multiple coupon links at once. You can also upload
      </p>

      <PrimaryInput
        onChange={(e) => setCode(e.currentTarget.value)}
        value={code}
        placeholder="Enter coupon code"
        required
        name="code"
        label="Assign a code to this upload"
        className="w-full"
      />

      <UploadFileNoDrag
        selectedFile={selectedFile}
        handleFileChange={handleFileChange}
      />

      <div className="mt-4">
        <PrimaryButton text="Submit" type="button" onClick={onSubmit} />
      </div>
    </div>
  );
};

export const FilterCoupons = ({
  params,
  showFilters,
  assignedUsers = false,
  usedUsers = false,
  applyFilters,
  resetFilters,
  setShowFilters,
}: {
  usedUsers?: boolean;
  showFilters: boolean;
  assignedUsers?: boolean;
  params: URLSearchParams;
  resetFilters: () => void;
  applyFilters: (x: CouponFilter | CouponUseFilter) => void;
  setShowFilters: (x: boolean) => void;
}) => {
  const [filters, setFilters] = React.useState({
    couponCode: '',
    userEmail: '',
  });
  const [userFilters, setUserFilters] = React.useState({
    useUserEmail: '',
    userName: '',
  });
  const [usedFilters, setUsedFilters] = React.useState({
    useOrderCode: '',
    useUserEmail: '',
  });

  const getFilterInputValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    if (assignedUsers) {
      return setUserFilters({ ...userFilters, [name]: value });
    }

    if (usedUsers) {
      return setUsedFilters({ ...usedFilters, [name]: value });
    }

    setFilters({ ...filters, [name]: value });
  };

  const filterCoupons = () => {
    applyFilters(
      assignedUsers ? userFilters : usedUsers ? usedFilters : filters
    );
  };

  React.useEffect(() => {
    const code = params.get('couponCode');
    const userName = params.get('userName');
    const userEmail = params.get('userEmail');
    const useUserEmail = params.get('useUserEmail');
    const useOrderCode = params.get('useOrderCode');

    setFilters({ userEmail: userEmail ?? '', couponCode: code ?? '' });
    setUserFilters({
      useUserEmail: useUserEmail ?? '',
      userName: userName ?? '',
    });
    setUsedFilters({
      useOrderCode: useOrderCode ?? '',
      useUserEmail: useUserEmail ?? '',
    });
  }, [params]);

  return (
    <div className="relative flex justify-end">
      <button
        onClick={() => setShowFilters(true)}
        className="h-12 w-12 rounded bg-tertiary-50 flex items-center justify-center"
      >
        <img src={filter} alt="filter" />
      </button>

      {showFilters && (
        <div className="absolute top-10 right-10 bg-white z-20 p-4 w-[300px] rounded">
          <p className="bold text-2xl">Filters</p>
          <div className="flex flex-col space-y-4 mt-2">
            {assignedUsers ? (
              <>
                <PrimaryInput
                  name="useUserEmail"
                  value={userFilters.useUserEmail}
                  className="w-full"
                  placeholder="Filter by user email"
                  label="Filter by user email"
                  onChange={getFilterInputValue}
                />
                <PrimaryInput
                  name="userName"
                  className="w-full"
                  value={userFilters.userName}
                  label="Filter by user name"
                  onChange={getFilterInputValue}
                  placeholder="Filter by user name"
                />
              </>
            ) : usedUsers ? (
              <>
                <PrimaryInput
                  name="useOrderCode"
                  className="w-full"
                  value={usedFilters.useOrderCode}
                  label="Filter by order code"
                  onChange={getFilterInputValue}
                  placeholder="Filter by order code"
                />
                <PrimaryInput
                  name="useUserEmail"
                  value={usedFilters.useUserEmail}
                  className="w-full"
                  placeholder="Filter by user email"
                  label="Filter by user email"
                  onChange={getFilterInputValue}
                />
              </>
            ) : (
              <>
                <PrimaryInput
                  name="couponCode"
                  value={filters.couponCode}
                  className="w-full"
                  placeholder="Filter by code"
                  label="Filter by coupon code"
                  onChange={getFilterInputValue}
                />
                <PrimaryInput
                  name="userEmail"
                  className="w-full"
                  value={filters.userEmail}
                  label="Filter by user email"
                  onChange={getFilterInputValue}
                  placeholder="Filter by user email"
                />
              </>
            )}
          </div>

          <div className="flex justify-between mt-4">
            <button
              type="button"
              onClick={resetFilters}
              className="text-sm text-primary-brown"
            >
              Reset
            </button>

            <button
              type="button"
              onClick={filterCoupons}
              className="py-1.5 px-3 rounded text-sm bg-primary-brown text-white"
            >
              Apply filters
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
