import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { onMessage } from 'firebase/messaging';
import { requestPushNotificationPermission } from '../../../helpers';
import { useAppDispatch, useAppSelector, usePermissions } from '../../../hooks';
import { DashboardRoutes } from '../../../routes';
import { authSelector, subscribeToNotifications } from '../../../store/slices';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { is_authenticated } = useAppSelector(authSelector);

  const navigate = useNavigate();
  const elements = useRoutes(DashboardRoutes);
  const { routeAdmin } = usePermissions();

  // const [audio, setAudio] = useState(new Audio('/attention.mp3'));

  const getToken = useCallback(async () => {
    if (is_authenticated) {
      const res = await requestPushNotificationPermission();
      if (res) {
        const { token, messaging } = res;

        if (token) {
          dispatch(subscribeToNotifications(token));
        }

        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);
          // dispatch(
          //   getAllOrders({
          //     query:
          //       user?.admin?.kitchens && user?.admin?.kitchens.length
          //         ? user?.admin?.kitchens[0].id
          //         : undefined,
          //   })
          // );
          // ...
        });
      }
    }
  }, [dispatch, is_authenticated]);

  // useEffect(() => {
  //   setAudio(new Audio('/attention.mp3'));

  //   if (
  //     orders.data &&
  //     orders.data.length > 0 &&
  //     orders.data.filter(
  //       (order) => order.status === 'PLACED' || order.status === 'PREPARING'
  //     ).length !== 0
  //   ) {
  //     if (!audio.paused) {
  //       audio.pause();
  //       audio.currentTime = 0;
  //     }

  //     audio.loop = true;
  //     audio.autoplay = true;

  //     audio.play().catch((e) => {
  //       window.addEventListener('click', () => {
  //         audio.play();
  //       });
  //     });
  //   } else {
  //     audio.pause();
  //     audio.currentTime = 0;
  //   }

  //   return () => {
  //     audio.pause();
  //     audio.currentTime = 0;
  //   };
  //   // eslint-disable-next-line
  // }, [orders]);

  // useEffect(() => {
  //   orders &&
  //     orders.data.length === 0 &&
  //     dispatch(
  //       getAllOrders({
  //         query:
  //           user?.admin?.kitchens && user?.admin?.kitchens.length
  //             ? user?.admin?.kitchens[0].id
  //             : undefined,
  //       })
  //     );
  //   // eslint-disable-next-line
  // }, [dispatch]);

  useEffect(() => {
    !is_authenticated && navigate('/auth/login');
  }, [is_authenticated, navigate]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  useEffect(() => {
    // routeAdmin();
  }, [routeAdmin]);

  return useMemo(() => <>{elements}</>, [elements]);
};

export default Dashboard;

/*
{showFrame && (
  <iframe
    title="audio-player"
    src="/audio.html"
    allow="autoplay"
  ></iframe>
)}
*/
