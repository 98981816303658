import {
  abandonedCheckout,
  addresses,
  brands,
  category,
  coupons,
  customers,
  giftcard,
  home,
  kitchens,
  meals,
  orders,
  promotions,
  riders,
  transactions,
  users,
  loyalty
} from '../assets/svg';

export const navLinks = [
  {
    name: 'Dashboard',
    path: '/dashboard/',
    icon: home,
    restrictions: ['OPERATIONS', 'MARKETING'],
    exemptions: [''],
  },
  {
    name: 'brand management',
    path: '/dashboard/brands',
    icon: brands,
    restrictions: [],
  },
  {
    name: 'kitchen management',
    path: '/dashboard/kitchens',
    icon: kitchens,
    restrictions: ['OPERATIONS'],
  },
  {
    name: 'category management',
    path: '/dashboard/categories',
    icon: category,
    restrictions: ['OPERATIONS'],
  },
  {
    name: 'orders management',
    path: '/dashboard/orders',
    icon: orders,
    restrictions: [],
  },
  {
    name: 'abandoned checkout',
    path: '/dashboard/abandoned-checkout',
    icon: abandonedCheckout,
    restrictions: [],
  },
  // {
  //   name: 'meal management',
  //   path: '/dashboard/meals',
  //   icon: meals,
  //   restrictions: [],
  // },
  {
    name: 'food items',
    path: '/dashboard/food-items',
    icon: meals,
    restrictions: [],
  },
  // {
  //   name: 'variants management',
  //   path: '/dashboard/variants',
  //   icon: variants,
  //   restrictions: [],
  // },
  {
    name: 'users',
    path: '/dashboard/users',
    icon: users,
    restrictions: ['OPERATIONS'],
  },
  {
    name: 'customers',
    path: '/dashboard/customers',
    icon: customers,
    restrictions: ['OPERATIONS'],
  },
  {
    name: 'promotions management',
    path: '/dashboard/promotions',
    icon: promotions,
    restrictions: ['OPERATIONS'],
  },
  {
    name: 'transactions',
    path: '/dashboard/transactions',
    icon: transactions,
    restrictions: ['OPERATIONS'],
  },
  {
    name: 'wallet transactions',
    path: '/dashboard/wallet-transactions',
    icon: transactions,
    restrictions: ['OPERATIONS'],
  },
  {
    name: 'delivery riders',
    path: '/dashboard/pilots',
    icon: riders,
    restrictions: [],
  },
  {
    name: 'addresses & locations',
    path: '/dashboard/addresses',
    icon: addresses,
    restrictions: ['OPERATIONS'],
  },
  {
    name: 'coupons',
    path: '/dashboard/coupons',
    icon: coupons,
    restrictions: ['OPERATIONS'],
  },
  {
    name: 'gift cards',
    path: '/dashboard/gift-cards',
    icon: giftcard,
    restrictions: ['OPERATIONS'],
  },
  {
    name: 'loyalty program',
    path: '/dashboard/loyalty-program',
    icon: loyalty,
    restrictions: ['OPERATIONS'],
  },
];

export const naira = '₦';
