import React from 'react';
import { useFetch } from './useFetch';
import { DateRangeParams, DateRanges, LoyaltyTable, Tier } from '../types';
import moment from 'moment';
import { loyaltyApi } from '../api';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from './redux.hooks';
import { getAllUsers } from '../store/slices';

export const useLoyalty = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const [tier, setTier] = React.useState<Tier>();
  const [modal, setModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRangeParams>();
  const [userName, setUserName] = React.useState('');
  const [tableParams, setTableParams] = React.useState<{
    table: LoyaltyTable;
    page: number;
  }>({
    table: 'Customers',
    page: 1,
  });

  const {
    useFetchAllActions,
    useFetchLoyaltyTiers,
    useFetchCoinTransactions,
    useFetchLoyaltyDashboard,
  } = useFetch();

  const { data: tiers } = useFetchLoyaltyTiers();
  const { data: actions } = useFetchAllActions({ limit: 100 });
  const { data: dashboard } = useFetchLoyaltyDashboard({ ...dateRange });
  const { data: coinTransactions } = useFetchCoinTransactions(
    {
      page: tableParams.page,
    },
    { enabled: tableParams.table === 'Transactions' }
  );
  const { data: redemptionLogs } = useFetchCoinTransactions(
    {
      transactionType: 'DEBIT',
      page: tableParams.page,
    },
    { enabled: tableParams.table === 'Redemption Logs' }
  );

  const handlePageClick = (data: any) => {
    const page = data.selected + 1;

    searchParams.set('page', page);
    navigate(`/dashboard/loyalty-program?${searchParams.toString()}`);
  };

  const handleChangeTable = (option: string) => {
    setSearchParams({
      page: '1',
      table: option,
    });
  };

  const onDateFilter = (
    range?: DateRanges,
    dates?: {
      startDate: Date | null;
      endDate: Date | null;
    }
  ) => {
    if (dates && dates.endDate && dates?.startDate) {
      setDateRange({
        startDate: moment(dates.startDate)
          .set({ hour: 1, minute: 0 })
          .toISOString(),
        endDate: moment(dates.endDate)
          .set({ hour: 1, minute: 0 })
          .toISOString(),
      });
    }
  };

  const onDateReset = () => {
    setDateRange({ startDate: '', endDate: '' });
  };

  const debounce = React.useCallback(
    (func: (...args: any[]) => void, delay: number) => {
      let timeoutId: NodeJS.Timeout;
      return (...args: any[]) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    },
    []
  );

  const handleDebouncedChange = React.useCallback((name: string) => {
    dispatch(getAllUsers({ query: `firstName=${name}` }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = React.useCallback(
    debounce(handleDebouncedChange, 200),
    [debounce, handleDebouncedChange]
  );

  const searchUsers = (x: string) => {
    setUserName(x);

    debouncedHandler(x);
  };

  const createNewTier = async (cb?: () => void) => {
    setLoading(true);

    try {
      if (tier) {
        const { max, min, percentage, ratePerCoin } = tier;
        await loyaltyApi.createTier({
          ...tier,
          maxPoint: max,
          minPoint: min,
          percentage: parseFloat(percentage),
          ratePerCoin: parseFloat(`${ratePerCoin}`),
        });

        toast.success('New tier created');
        queryClient.refetchQueries(['loyalty-tiers']);

        cb && cb();
      }
    } catch (error) {
      toast.error('Error creating tier');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const page = searchParams.get('page');
    const table = searchParams.get('table');

    if (table || page) {
      setTableParams({
        table: (table as LoyaltyTable) ?? 'Customers',
        page: parseInt(page ?? '1'),
      });
    }

    if (table === 'Customers' || tableParams.table === 'Customers') {
      dispatch(getAllUsers({ query: 'limit=10', page: parseInt(page ?? '1') }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return {
    tier,
    tiers,
    modal,
    actions,
    loading,
    userName,
    dashboard,
    tableParams,
    searchParams,
    redemptionLogs,
    coinTransactions,
    setTier,
    setModal,
    onDateReset,
    setUserName,
    searchUsers,
    onDateFilter,
    createNewTier,
    handlePageClick,
    setSearchParams,
    handleChangeTable,
  };
};
