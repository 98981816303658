import { Cell } from 'react-table';
import {
  FoodGroup,
  FoodItem,
  IBrand,
  IVariant,
  Option,
  ResolvedResponse,
} from '../types';
import {
  FoodItemScreenFilters,
  TableData,
} from '../views/features/dashboard/food-items/types';

const mealStatus = [
  { value: 'INACTIVE', option: 'Inactive' },
  { value: 'ACTIVE', option: 'Active' },
  { value: 'PREPARING', option: 'Preparing' },
  { value: 'OUT_OF_STOCK', option: 'Out of stock' },
] as { value: string; option: string }[];

const mealType = [
  { value: 'VARIABLE', option: 'Variable' },
  { value: 'FIXED', option: 'Fixed' },
] as { value: string; option: string }[];

const mealsColumns = [
  {
    Header: 'Meal',
    accessor: 'name',
  },
  {
    Header: 'Brand',
    accessor: 'brand',
    Cell: ({ row }: Cell) => {
      if (row.values.brand) {
        return row.values.brand.name;
      } else {
        return '';
      }
    },
  },
  {
    Header: 'Is Available?',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Price',
    accessor: 'basePrice',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

export const ownerMealsColumns = [
  {
    Header: 'Meal',
    accessor: 'name',
  },
  {
    Header: 'Brand',
    accessor: 'brand',
    Cell: ({ row }: Cell) => {
      if (row.values.brand) {
        return row.values.brand.name;
      } else {
        return '';
      }
    },
  },
  {
    Header: 'Is Available?',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Inactive',
  },
  {
    Header: 'Price',
    accessor: 'basePrice',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

const choicesColumns = [
  {
    Header: 'Choice Title',
    accessor: 'title',
  },
  {
    Header: 'Variants',
    accessor: 'variants',
    Cell: ({ row }: Cell) =>
      row.values.variants.map((variant: IVariant) => variant.name).join(', '),
  },
];

export const foodItemColumns = (isOwner?: boolean) => {
  return [
    {
      Header: 'Item name',
      accessor: 'name',
    },
    {
      Header: 'Price',
      accessor: 'price',
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      Cell: ({ row }: Cell) => row.values.brand?.name ?? '',
    },
    {
      Header: 'Item type',
      accessor: 'comboItem',
      Cell: ({ row }: Cell) => (row.values.comboItem ? 'Combo' : 'Non-combo'),
    },
    ...(isOwner
      ? [
          {
            Header: 'Is Available?',
          },
        ]
      : []),
    {
      Header: 'Date Created',
      accessor: 'createdAt',
    },
    {
      Header: 'Date Updated',
      accessor: 'updatedAt',
    },
    {
      Header: 'Duplicate',
    },
    {
      Header: 'Delete',
    },
  ];
};

export const foodGroupColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Identifier',
    accessor: 'identifier',
  },
  {
    Header: 'Minimum Selection',
    accessor: 'minimumSelection',
    Cell: ({ row }: Cell) => row.values.minimumSelection ?? 0,
  },
  {
    Header: 'Maximum Selection',
    accessor: 'maximumSelection',
    Cell: ({ row }: Cell) => row.values.maximumSelection ?? 0,
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Date Updated',
    accessor: 'updatedAt',
  },
  {
    Header: 'Delete',
  },
  {
    Header: 'Duplicate',
  },
];

export const optionsColumns = (brands?: IBrand[]) => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Option price',
      accessor: 'price',
    },
    {
      Header: 'Item available?',
    },
    {
      Header: 'Minimum Selection',
      accessor: 'minimumSelection',
      Cell: ({ row }: Cell) => row.values.minimumSelection ?? 0,
    },
    {
      Header: 'Maximum Selection',
      accessor: 'maximumSelection',
      Cell: ({ row }: Cell) => row.values.maximumSelection ?? 0,
    },
    {
      Header: 'Related food item',
      accessor: 'sameAsFoodItem',
      Cell: ({ row }: Cell) =>
        row.values.sameAsFoodItem
          ? `${row.values.sameAsFoodItem.name} ${
              row.values.sameAsFoodItem.brandId && brands
                ? `(${
                    brands.find(
                      (b) => b.id === row.values.sameAsFoodItem.brandId
                    )?.name
                  })`
                : ''
            } `
          : '-',
    },
    {
      Header: 'Groups',
      accessor: 'foodGroup',
      Cell: ({ row }: Cell) => {
        return (
          <p>
            {row.values.foodGroup
              .map(
                (group: FoodGroup) =>
                  `${group.name}${
                    group.identifier ? ` (${group.identifier})` : ''
                  }`
              )
              .join(', ')}
          </p>
        );
      },
    },
    {
      Header: 'Delete',
    },
    {
      Header: 'Duplicate',
    },
  ];
};

export { mealStatus, mealType, mealsColumns, choicesColumns };

export const selectionCounts = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
];

export const foodItemStatus = [
  { label: 'Open', value: 'OPEN' },
  { label: 'Closed', value: 'CLOSED' },
  { label: 'Out of stock', value: 'OUT_OF_STOCK' },
  { label: 'Deleted', value: 'DELETED' },
];

export const foodItemType = [
  { label: 'Combo', value: 1 },
  { label: 'Non-combo', value: 0 },
];

export const optionActiveStatus = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];

export const foodItemServices = [
  { label: 'Delivery', value: 'DELIVERY' },
  { label: 'Pickup', value: 'PICKUP' },
  { label: 'Scheduled', value: 'SCHEDULED' },
  { label: 'Groups', value: 'GROUPS' },
  { label: 'Events', value: 'EVENTS' },
];

export const discountType = [
  { value: 'percent', label: 'Percent' },
  { value: 'amount', label: 'Discount Amount' },
];

export const tableData: (
  items?: ResolvedResponse<FoodItem[]>,
  groups?: ResolvedResponse<FoodGroup[]>,
  options?: ResolvedResponse<Option[]>,
  brands?: IBrand[],
  isOwner?: boolean
) => TableData = (items, groups, options, brands, isOwner) => {
  return {
    FOOD_ITEMS: {
      data: items?.data ?? [],
      tableFor: 'foodItems',
      name: 'Food item',
      column: foodItemColumns(isOwner),
      pagination: items?.pagination,
      searchQuery: 'foodItemName',
    },
    FOOD_GROUPS: {
      data: groups?.data ?? [],
      tableFor: 'foodItems',
      column: foodGroupColumns,
      name: 'Food group',
      pagination: groups?.pagination,
      searchQuery: 'foodGroupName',
    },
    OPTIONS: {
      data: options?.data ?? [],
      tableFor: 'options',
      column: optionsColumns(brands),
      name: 'Option',
      pagination: options?.pagination,
      searchQuery: 'foodGroupOptionName',
    },
  };
};

export const filters: (
  totalItems?: number,
  totalGroups?: number,
  totalOptions?: number
) => {
  name: string;
  value: FoodItemScreenFilters;
  count?: number;
}[] = (totalItems, totalGroups, totalOptions) => {
  return [
    {
      name: 'Food Items',
      value: 'FOOD_ITEMS',
      count: totalItems,
    },
    {
      name: 'Food Groups',
      value: 'FOOD_GROUPS',
      count: totalGroups,
    },
    {
      name: 'Options',
      value: 'OPTIONS',
      count: totalOptions,
    },
  ];
};
